import React, { useEffect, useRef } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css'; // Ensure Bootstrap CSS is imported

const roadmapItems = [
  { 
    quarter: "Q3, 2017", 
    title: "PiplCoin/PiplShare", 
    description: [
      "Balancing transparency, intellectual property rights,",
      "and passive income generation."
    ], 
    status: "completed", 
    showReadMore: true, 
    readMoreLink: "https://docs.waveswaps.com/ws-official/historical-evolution/startup-ecosystem/piplcoin-piplshare"
  },
  { 
    quarter: "Q4, 2017", 
    title: "PronCoin / PronWorld", 
    description: [
      "Disrupted traditional models but encountered skepticism",
      "regarding blockchain's applicability in adult industry."
    ], 
    status: "completed", 
    showReadMore: true, 
    readMoreLink: "https://docs.waveswaps.com/ws-official/historical-evolution/startup-ecosystem/proncoin-pronworld"
  },
  { 
    quarter: "2018", 
    title: "Experimental Projects", 
    description: [
      "CarbCoin, AirPod, Canabio, LifeOnScreen.",
      "Leading to varied public perception."
    ], 
    status: "completed", 
    showReadMore: true, 
    readMoreLink: "https://docs.waveswaps.com/ws-official/historical-evolution/startup-ecosystem/experimental-projects"
  },
  { 
    quarter: "2020", 
    title: "Geton Startup Ecosystem", 
    description: [
      "GrossusBlockchain (GROSH) & Gx.. wallet.",
      "Supplies allocation through project platforms."
    ], 
    status: "completed", 
    showReadMore: true, 
    readMoreLink: "https://docs.waveswaps.com/ws-official/historical-evolution/startup-ecosystem"
  },
  { 
    quarter: "2021", 
    title: "Grosh Mining Completed", 
    description: [
      "Dynamic Price Modeling Concept.",
      "J-Curve through Project Matrix Model."
    ], 
    status: "completed", 
    showReadMore: true, 
    readMoreLink: "https://docs.waveswaps.com/ws-official/historical-evolution/grosh-mining"
  },
  { 
    quarter: "Q4, 2021", 
    title: "Geton Trading Order", 
    description: [
      "Structure based token trading model.",
      "CryptoCode token revamp model."
    ], 
    status: "completed", 
    showReadMore: true, 
    readMoreLink: "https://docs.waveswaps.com/ws-official/historical-evolution/trading-order"
  },
  { 
    quarter: "2022", 
    title: "GetonLedger - POA", 
    description: [
      "Several MetaCoin innitiatives.",
      "Token allocation to custody wallets."
    ], 
    status: "completed", 
    showReadMore: true, 
    readMoreLink: "https://docs.waveswaps.com/ws-official/historical-evolution/getonledger-poa"
  },
  { 
    quarter: "Q1, 2023", 
    title: "Token Recycle - GBL", 
    description: [
      "Recycle, Revitalize, Revamp.",
      "Multi-chain token allocation & DEX."
    ], 
    status: "completed", 
    showReadMore: true, 
    readMoreLink: "https://docs.waveswaps.com/ws-official/historical-evolution/token-recycle-gbl"
  },
  { 
    quarter: "Q4, 2024", 
    title: "Token Revamp - WAVE", 
    description: [
      "Complete DAO control over governance.",
      "Price Prediction Game launched."
    ], 
    status: "completed", 
    showReadMore: true, 
    readMoreLink: "https://docs.waveswaps.com/ws-official/historical-evolution/token-revamp-wave"
  },
  { 
    quarter: "Q4, 2024", 
    title: "Development Completed!", 
    description: [
      "Geton Ecosystem's development completed.",
      "Community manages platform decisions."
    ], 
    status: "completed-end", 
    showReadMore: true, 
    readMoreLink: "https://docs.waveswaps.com/ws-official/historical-evolution/geton-global"
  },
];


function RoadmapSection() {
  const timelineRef = useRef(null);

  useEffect(() => {
    const inProgressItem = document.querySelector('.items-list.completed-end');
    if (inProgressItem && timelineRef.current) {
      const offsetLeft = inProgressItem.offsetLeft - (timelineRef.current.offsetWidth / 2) + (inProgressItem.offsetWidth / 2);
      timelineRef.current.scrollLeft = offsetLeft;
    }
  }, []);

  return (
    <section className="roadmap rmtable">
      <h2 className="roadmap-header pageTitle">Roadmap</h2>
      <p className="hero-p sub-header">Discover our roadmap with completed, current, and upcoming milestones displayed on an interactive timeline.</p> 
      <div className="horizontal-timeline" ref={timelineRef}>
        <ul className="list-inline items">
          {roadmapItems.map((item, index) => (
            <li key={index} className={`list-inline-item items-list ${item.status}`}>
              <div className="px-4">
                <div className={`event-date badge ${getBadgeClass(item.status)}`}>{item.quarter}</div>
                <h5 className="pt-2">{item.title}</h5>
                {item.description.map((line, lineIndex) => (
                  <p key={lineIndex} className="text-muted mb-1">{line}</p>
                ))}
                <div className={`read-more-container ${!item.showReadMore ? 'empty-button' : ''}`}>
                  {item.showReadMore && (
                    <a href={item.readMoreLink} target="_blank"  className="btn btn-primary mt-2 btn-sm">Read more</a>
                  )}
                </div>
              </div>
            </li>
          ))}
        </ul>
      </div>
    </section>
  );
}

// Helper function to determine badge class based on status
const getBadgeClass = (status) => {
  switch (status) {
    case 'completed-end':
      return 'bg-success'; // Green badge for completed
      case 'completed':
        return 'bg-success'; // Green badge for completed
    case 'in-progress':
      return 'bg-warning'; // Yellow badge for in-progress
    case 'planned':
      return 'bg-info'; // Blue badge for planned
    default:
      return '';
  }
};

export default RoadmapSection;
