import React from 'react';
import { useNavigate } from 'react-router-dom';

const nodesImg = '/assets/images/nodes.svg';
const nodesImgT = '/assets/images/testnodes.svg';
const botsImgv1 = '/assets/images/V1gbl.svg';
const botsImgv3 = '/assets/images/V3gbl.svg';
const botsImggStake = '/assets/images/gblstake.svg';
const botsImgwStake = '/assets/images/wavestake.svg';


const CardMarqueeSection = () => {
  const navigate = useNavigate();

  const openLink = (_link) => {
    navigate(_link);
  }
  return (
   
      <div className="marquee"
        onMouseEnter={(e) => e.currentTarget.style.animationPlayState = 'paused'}
        onMouseLeave={(e) => e.currentTarget.style.animationPlayState = 'running'}
      >
        {[...Array(2)].map((_, index) => (
          <div key={index} className="marquee-content">
            {/* Card components */}
            

           
            
             
              <button
                className="btn wcard-v1-button"
                onClick={() => openLink('/swap-v1')}
              >
                 <img src={botsImgv1} alt="GBL Bots" className="wcard-image-v1" />
              </button>
           
             
             
              <button
                className="btn wcard-v3-button"
                onClick={() => openLink('/swap-v3')}
              >
                <img src={botsImgv3} alt="GBL Bots" className="wcard-image-v3" />
              </button>
            
              <button
                className="btn wcard-button"
                onClick={() => openLink('/staking-gbl')}
              >
                <img src={botsImggStake} alt="GBL Bots" className="wcard-image" />
              </button>
       
            
              <button
                className="btn wcard-button"
                onClick={() => openLink('/staking')}
              >
                <img src={botsImgwStake} alt="GBL Bots" className="wcard-image" />
              </button>
           
          </div>
        ))}
      </div>
  );
};

export default CardMarqueeSection;
