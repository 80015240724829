import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { FaInstagram, FaTelegramPlane, FaYoutube } from 'react-icons/fa';
import { SiMedium } from "react-icons/si";
import { SiCoinmarketcap } from "react-icons/si";
import { BiRocket, BiCard } from 'react-icons/bi';
import { MdOutlineIntegrationInstructions } from "react-icons/md";
import { LuArrowLeftRight } from "react-icons/lu";
import { useNavigate } from 'react-router-dom';

const TwitterIcon = () => (
  <svg className="twitter" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" width="19" height="19">
    <path d="M389.2 48h70.6L305.6 224.2 487 464H345L233.7 318.6 106.5 464H35.8L200.7 275.5 26.8 48H172.4L272.9 180.9 389.2 48zM364.4 421.8h39.1L151.1 88h-42L364.4 421.8z" />
  </svg>
);

const Footer = () => {
  const navigate = useNavigate();

  const openLink = (_link) => {
    navigate(_link);
  }
  return (
<footer className="footer">
  <Container>
    <Row className="footer-content align-items-center">
      {/* Social Icons */}
      <Col xs={12} md={4} className="text-center mb-3 mb-md-0 footer-social">
        <a href="https://t.me/metacoincommunity" target="_blank" rel="noopener noreferrer" className="social-icon telegram">
          <FaTelegramPlane size={21} />
          <a href="https://twitter.com/GetonGlobal" target="_blank" rel="noopener noreferrer me" className="social-icon twitter">
              <TwitterIcon />
            </a>
        </a>
      </Col>
      {/* Copyright */}
      <Col xs={12} md={4} className="text-center">
        <p className="footer-copy">&copy; 2024 Geton Global. All rights reserved.</p>
      </Col>

      {/* Footer Links */}
      <Col xs={12} md={4} className="text-center mb-3 mb-md-0 footer-links">
        <a href="https://docs.waveswaps.com/ws-official/historical-evolution/geton-global-ppg/privacy-policy" target="_blank" className="footer-link">Privacy</a>
        <a href="https://docs.waveswaps.com/ws-official/historical-evolution/geton-global-ppg/terms-and-conditions" target="_blank" className="footer-link">Terms</a>
        <a href="https://docs.waveswaps.com/ws-official/historical-evolution/geton-global-ppg/crypto-spotlight" target="_blank" className="footer-link">Spotlight</a>
        <a href="https://docs.waveswaps.com/dao-governance/dao-governance" target="_blank" className="footer-link">DAO</a>
        <a href="https://docs.waveswaps.com/ws-official/historical-evolution/geton-global-ppg/price-prediction-game" target="_blank" className="footer-link">PPG</a>
      </Col>
    </Row>
  </Container>
</footer>


  );
};

export default Footer;
