// Imports
import React, { useState, useEffect, useCallback } from 'react';
import { BrowserProvider, Contract, formatUnits, formatEther, parseUnits, parseEther } from 'ethers';
import SETTINGS from "../../SETTINGS";
import WeightedVotingForm from './WeightedVotingForm'; // Import the Voting Form for Weighted
import { BiDislike, BiLike, BiFlag } from "react-icons/bi";
import WeightedVoting from '../../abis/WeightedVoting.json';
import '../../styles/ProposalList.css';

// Constants
const reportThreshold = 5; // Set the reporting threshold here (or fetch from contract if needed)
const proposalsPerPage = 10; // Limit proposals per page

// Functions
const WeightedProposalList = ({ provider, selectedAccount, isConnected }) => {
  // State Management
  const [proposals, setProposals] = useState([]);
  const [expandedProposalIndex, setExpandedProposalIndex] = useState(null); // Handle accordion expansion
  const [walletAddress, setWalletAddress] = useState(null); // State to store the wallet address
  const [filter, setFilter] = useState('all'); // Filter state: 'all', 'open', 'finished'
  const [categoryFilter, setCategoryFilter] = useState('all'); // Category filter state
  const [proposalDuration, setProposalDuration] = useState(0); // Proposal duration
  const [showVoterHistoryIndex, setShowVoterHistoryIndex] = useState(null); // Track which proposal's voter history is shown
  const [hasVotedMap, setHasVotedMap] = useState({}); // Track if a user has voted
  const [rewardClaimedMap, setRewardClaimedMap] = useState({}); // Track reward claims for each proposal and user
  const [voterHistory, setVoterHistory] = useState([]); // Store fetched voter history
  const [reportMap, setReportMap] = useState({});  // Store reported status for proposals
  const [reportedWalletsMap, setReportedWalletsMap] = useState({}); // Track reporting wallets for each proposal
  const [reportConfirmedMap, setReportConfirmedMap] = useState({}); // Track checkbox state per proposal
  const [loadingMap, setLoadingMap] = useState({}); // Track loading state per proposal
  const [showReportersIndex, setShowReportersIndex] = useState(null); // Track the proposal's reporters list visibility
  const [reportCounts, setReportCounts] = useState({}); // Track the count of reports per proposal
  const [currentPage, setCurrentPage] = useState(1); // Track current page
  const [selectedABI] = useState(WeightedVoting);  // Store the ABI

  // ---- Proposal Fetching & Initialization ----
  const fetchWalletAddress = useCallback(async () => {
    if (!provider) return;
    try {
      const ethersProvider = new BrowserProvider(provider);
        const signer = await ethersProvider.getSigner();
      const address = await signer.getAddress();
      setWalletAddress(address); // Set wallet address
    } catch (error) {
      console.error('Error fetching wallet address:', error);
    }
  }, []);

  // Fetch proposals and duration
  const fetchProposalsAndDuration = useCallback(async () => {
    if (!provider) return;
    try {
      const ethersProvider = new BrowserProvider(provider);
      const signer = await ethersProvider.getSigner();
      const daoContract = new Contract(SETTINGS.weightedDaoContract, WeightedVoting, signer);

      // Fetch duration and proposals
      const fetchedProposalDuration = await daoContract.proposalDuration();
      setProposalDuration(Number(fetchedProposalDuration) * 60 * 60 * 1000);

      const proposalsResult = await daoContract.getProposals();
      const structuredProposals = proposalsResult.subjects.map((_, index) => ({
        index,
        subject: proposalsResult.subjects[index],
        category: proposalsResult.categories[index],
        description: proposalsResult.descriptions[index],
        yesVotes: proposalsResult.yesVotes[index],
        noVotes: proposalsResult.noVotes[index],
        proposer: proposalsResult.proposers[index],
        isActive: proposalsResult.isActive[index],
        creationTime: Number(proposalsResult.createdAt[index].toString()) * 1000,
      }));
      setProposals(structuredProposals);
    } catch (error) {
      console.error('Error fetching proposals:', error);
    }
  }, [provider]);

  const handleVoteCallback = async (proposalIndex) => {
    console.log(`Vote registered for proposal index: ${proposalIndex}`);
  
    // Fetch the updated voter history and voting status
    await fetchVoterHistory(proposalIndex);
    await fetchVotingStatus();
  
    // Re-fetch proposals and voting status after a vote is submitted
    fetchProposalsAndDuration(); // Refresh proposals
  };
  

  // Fetch voting status for each proposal
  const fetchVotingStatus = useCallback(async () => {
    if (!provider || proposals.length === 0) return;
    try {
      const ethersProvider = new BrowserProvider(provider);
        const signer = await ethersProvider.getSigner();
      const userAddress = await signer.getAddress();
      const daoContract = new Contract(SETTINGS.weightedDaoContract, WeightedVoting, signer.provider);

      const updatedHasVotedMap = {};

      for (const proposal of proposals) {
        const voterHistory = await daoContract.getVoterHistory(proposal.index);

        // Check if the user has voted on the proposal
        const hasVoted = voterHistory[0].includes(userAddress);
        updatedHasVotedMap[proposal.index] = hasVoted;
      }

      setHasVotedMap(updatedHasVotedMap);
    } catch (error) {
      console.error('Error fetching voting status:', error);
    }
  }, [proposals]);

  // Updated useEffect to avoid infinite loops and refresh proposals/voting status
  useEffect(() => {
    if (provider) {
      fetchProposalsAndDuration();
      fetchWalletAddress();
      fetchVotingStatus(); // Fetch voting status to update hasVotedMap
    }
  }, [provider, fetchProposalsAndDuration, fetchWalletAddress, fetchVotingStatus]);

// ---- Filtering ----
  // Sort proposals by creation time (newest first)
  const sortedProposals = proposals.sort((a, b) => b.creationTime - a.creationTime);

  // Filter proposals by status and category
  const filteredProposals = sortedProposals.filter((proposal) =>
    (filter === 'all' ? true : filter === 'open' ? proposal.isActive : !proposal.isActive) &&
    (categoryFilter === 'all' ? true : proposal.category === categoryFilter)
  );

  // ---- Pagination ----
  // Pagination logic
  const indexOfLastProposal = currentPage * proposalsPerPage;
  const indexOfFirstProposal = indexOfLastProposal - proposalsPerPage;
  const currentProposals = filteredProposals.slice(indexOfFirstProposal, indexOfLastProposal); // Now using currentProposals

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const renderPagination = () => {
    const pageNumbers = [];
    for (let i = 1; i <= Math.ceil(filteredProposals.length / proposalsPerPage); i++) {
      pageNumbers.push(i);
    }

    return (
      <div className="pagination">
        {pageNumbers.map((number) => (
          <button
            key={number}
            onClick={() => handlePageChange(number)}
            className={currentPage === number ? 'active' : ''}
          >
            {number}
          </button>
        ))}
      </div>
    );
  };

  // Function to handle vote submission
  const handleVoteSubmit = (proposalIndex) => {
    const proposal = proposals.find(p => p.index === proposalIndex);
  
    // Ensure the proposal is still active before allowing the vote
    if (isProposalExpired(proposal.creationTime)) {
      alert('This proposal has already expired. Voting is not allowed.');
      return;
    }
  
    handleVoteCallback(proposalIndex);  // Trigger the callback to refresh the list and hide the form
  };
  

  // Helper function to check if the proposal has expired
  const isProposalExpired = (creationTime) => {
    const now = Date.now(); // Get the current timestamp in milliseconds
    const expirationTime = creationTime + proposalDuration; // Calculate expiration time
    return now >= expirationTime; // Return true if the proposal has expired
  };

  // Toggle the accordion for showing proposal details
  const toggleAccordion = (index) => {
    setExpandedProposalIndex(expandedProposalIndex === index ? null : index);
  };

  // Fetch voter history for a proposal
  const fetchVoterHistory = async (proposalIndex) => {
    try {
      const ethersProvider = new BrowserProvider(provider);
        const signer = await ethersProvider.getSigner();
      const daoContract = new Contract(SETTINGS.weightedDaoContract, WeightedVoting, signer);
      const history = await daoContract.getVoterHistory(proposalIndex);

      const voters = history[0]; // Voters' addresses
      const votes = history[1]; // Yes/No votes

      const voterHistoryData = voters.map((voter, index) => ({
        voterAddress: voter,
        vote: votes[index] ? 'Yes' : 'No',
      }));

      setVoterHistory(voterHistoryData);
    } catch (error) {
      console.error('Error fetching voter history:', error);
    }
  };

  const toggleVoterHistory = async (proposalIndex) => {
    if (showVoterHistoryIndex === proposalIndex) {
      setShowVoterHistoryIndex(null); // Hide voter history
    } else {
      await fetchVoterHistory(proposalIndex); // Fetch and show voter history
      setShowVoterHistoryIndex(proposalIndex); // Show voter history
    }
  };
  

  // Handle proposal expiration
  const calculateTimeRemaining = (creationTime, index) => {
    const now = Date.now();
    const expirationTime = creationTime + proposalDuration;
    const timeRemaining = expirationTime - now;

    if (timeRemaining <= 0) {
      setProposals((prevProposals) =>
        prevProposals.map((proposal, i) =>
          i === index ? { ...proposal, isActive: false } : proposal
        )
      );
      return `0 Hours 0 Min`; // Proposal has expired
    }

    const minutes = Math.floor((timeRemaining / 1000 / 60) % 60);
    const hours = Math.floor(timeRemaining / 1000 / 60 / 60);
    return `${hours} h ${minutes} min`;
  };

// ---- Rewarding ----

  const handleClaimRewards = async (proposalIndex, isSubmitter = false) => {
    try {
      const ethersProvider = new BrowserProvider(provider);
        const signer = await ethersProvider.getSigner();
      const daoContract = new Contract(SETTINGS.weightedDaoContract, WeightedVoting, signer);

      // Finalize the proposal if it's still active
      const proposal = await daoContract.getProposal(proposalIndex);
      if (proposal.isActive) {
        const tx = await daoContract.finalizeProposal(proposalIndex);
        await tx.wait();
      }

      // Determine if claiming as submitter or voter and claim rewards
      const claimType = isSubmitter ? "Submitter" : "Voter";
      const claimTx = await daoContract.claimReward(proposalIndex, {
        value: parseEther("0.01"),  // Service fee if applicable
      });
      await claimTx.wait();

      console.log(`${claimType} rewards claimed for proposal ${proposalIndex}`);
      
      // Persist the claim status locally
      persistClaimedRewards(proposalIndex, isSubmitter);
      
      // Mark the rewards as claimed in state
      setRewardClaimedMap((prevMap) => ({
        ...prevMap,
        [proposalIndex]: true,
      }));
    } catch (error) {
      console.error(`Error claiming ${isSubmitter ? "submitter" : "voter"} rewards for proposal ${proposalIndex}:`, error);
    }
  };

  // Function to render claim buttons based on local storage and rewardClaimedMap
  const renderClaimButtons = (proposal) => {
    const claimedRewards = hasClaimedRewardLocalStorage(proposal.index); // Check local storage

    if (!proposal.isActive && !rewardClaimedMap[proposal.index] && !claimedRewards.submitter && !claimedRewards.voter) {
      return (
        <div>
          {walletAddress === proposal.proposer && !claimedRewards.submitter && (
            <button
              className="claim-rewards-button"
              onClick={() => handleClaimSubmitterRewards(proposal.index)}
            >
              Claim Rewards as Submitter
            </button>
          )}

          {hasVotedMap[proposal.index] && !claimedRewards.voter && (
            <button
              className="claim-rewards-button"
              onClick={() => handleClaimVoterRewards(proposal.index)}
            >
              Claim Rewards as Voter
            </button>
          )}

          {walletAddress === proposal.proposer && hasVotedMap[proposal.index] && !claimedRewards.submitter && !claimedRewards.voter && (
            <div>
              <p>You are both the proposer and a voter. Claim both rewards.</p>
            </div>
          )}
        </div>
      );
    }
    return null;  // Return null if no buttons should be displayed
  };


// Use localStorage to persist claimed rewards across refreshes
const persistClaimedRewards = (proposalIndex, isSubmitter = false) => {
  const claimedRewards = JSON.parse(localStorage.getItem('claimedRewards')) || {};
  claimedRewards[proposalIndex] = { submitter: isSubmitter, voter: !isSubmitter };
  localStorage.setItem('claimedRewards', JSON.stringify(claimedRewards));
};
const hasClaimedRewardLocalStorage = (proposalIndex) => {
  const claimedRewards = JSON.parse(localStorage.getItem('claimedRewards')) || {};
  return claimedRewards[proposalIndex] || { submitter: false, voter: false };
};

// Call this function to claim rewards as a submitter
const handleClaimSubmitterRewards = async (proposalIndex) => {
  await handleClaimRewards(proposalIndex, true);
};

// Call this function to claim rewards as a voter
const handleClaimVoterRewards = async (proposalIndex) => {
  await handleClaimRewards(proposalIndex, false);
};

// Fetch the claim status from localStorage on page load
useEffect(() => {
  const loadClaimedRewardsFromStorage = () => {
    const claimedRewards = JSON.parse(localStorage.getItem('claimedRewards')) || {};
    setRewardClaimedMap(claimedRewards);
  };

  loadClaimedRewardsFromStorage();
}, []);


// Finalize a proposal
  const finalizeProposal = useCallback(async (proposalIndex) => {
    try {
      const ethersProvider = new BrowserProvider(provider);
      const signer = await ethersProvider.getSigner();
      const daoContract = new Contract(SETTINGS.weightedDaoContract, WeightedVoting, signer);

      // Call the finalizeProposal function in the smart contract
      const tx = await daoContract.finalizeProposal(proposalIndex);

      // Wait for the transaction to be mined
      await tx.wait();

      console.log(`Proposal ${proposalIndex} has been finalized.`);
    } catch (error) {
      console.error(`Error finalizing proposal ${proposalIndex}:`, error);
    }
  }, []); // Ensure this only changes when contractAddress or WeightedVoting change

// Define finalizeExpiredProposals with useCallback
const finalizeExpiredProposals = useCallback(async () => {
  const now = Date.now();

  // Process each proposal and finalize if expired
  for (const [index, proposal] of proposals.entries()) {
    const expirationTime = proposal.createdAt + proposalDuration;

    if (now > expirationTime && proposal.isActive) {
      try {
        // Call finalizeProposal for expired proposals
        await finalizeProposal(proposal.index);

        // Update the state to mark the proposal as inactive
        setProposals((prevProposals) =>
          prevProposals.map((p, i) => 
            i === index ? { ...p, isActive: false } : p
          )
        );
      } catch (error) {
        console.error(`Error finalizing proposal at index ${index}:`, error);
      }
    }
  }
}, [proposals, proposalDuration, finalizeProposal]);

// Run finalizeExpiredProposals when proposals change
useEffect(() => {
  finalizeExpiredProposals();
}, [finalizeExpiredProposals]);

// ---- Reporting ----
  // Function to handle reporting a proposal
  const handleReportProposal = async (proposalIndex) => {
    // Ensure user has confirmed for this proposal
    if (!reportConfirmedMap[proposalIndex]) return;

    try {
      // Start loading spinner for this proposal
      setLoadingMap((prevState) => ({
        ...prevState,
        [proposalIndex]: true,
      }));

      const ethersProvider = new BrowserProvider(provider);
      const signer = await ethersProvider.getSigner();
      const daoContract = new Contract(SETTINGS.weightedDaoContract, WeightedVoting, signer);

      // Check if the user has already reported this proposal
      if (reportMap[proposalIndex]) {
        throw new Error("You have already reported this proposal");
      }

      // Call the smart contract's reportProposal function
      await daoContract.reportProposal(proposalIndex);

      // Update report state to mark the proposal as reported
      setReportMap((prevState) => ({
        ...prevState,
        [proposalIndex]: true,
      }));

      // Add the reporter's wallet to the list of reported wallets
      const reporterWallet = await signer.getAddress();
      setReportedWalletsMap((prevState) => ({
        ...prevState,
        [proposalIndex]: [...(prevState[proposalIndex] || []), reporterWallet],
      }));

      // Increment report count
      setReportCounts((prevCounts) => ({
        ...prevCounts,
        [proposalIndex]: (prevCounts[proposalIndex] || 0) + 1,
      }));

      console.log(`Proposal ${proposalIndex} successfully reported by wallet ${reporterWallet}`);
    } catch (error) {
      // Improved error message handling to include the proposal index
      console.error(`Error reporting the proposal at index ${proposalIndex}:`, error.message || error);
    } finally {
      // Stop loading spinner for this proposal
      setLoadingMap((prevState) => ({
        ...prevState,
        [proposalIndex]: false,
      }));
    }
  };



// Function to check if the current wallet has already reported the proposal
const hasAlreadyReported = (proposalIndex) => {
  return reportedWalletsMap[proposalIndex]?.includes(walletAddress);
};

// Handle checkbox state for reporting confirmation (per proposal)
const handleCheckboxChange = (proposalIndex) => {
  setReportConfirmedMap((prevState) => ({
    ...prevState,
    [proposalIndex]: !prevState[proposalIndex], // Toggle the confirmation checkbox
  }));
};

const fetchReportedWallets = useCallback(
  async (proposalIndex) => {
    try {
      const ethersProvider = new BrowserProvider(provider);
      const signer = await ethersProvider.getSigner();
      const daoContract = new Contract(SETTINGS.weightedDaoContract, WeightedVoting, signer);

      const reporters = await daoContract.getReporters(proposalIndex);

      // Update state only if there are changes
      setReportedWalletsMap((prevState) => ({
        ...prevState,
        [proposalIndex]: reporters,
      }));
    } catch (error) {
      console.error(`Error fetching reported wallets for proposal ${proposalIndex}:`, error);
    }
  },
  [provider] // Add provider dependency here, or remove if static
);

useEffect(() => {
  if (proposals.length > 0) {
    proposals.forEach((proposal) => {
      fetchReportedWallets(proposal.index);
    });
  }
}, [proposals, fetchReportedWallets]);

// Toggle function for showing the reporters list
const toggleReportersList = async (proposalIndex) => {
  if (showReportersIndex === proposalIndex) {
    setShowReportersIndex(null); // Hide the list if already open
  } else {
    await fetchReportedWallets(proposalIndex); // Fetch the reporters if the list is closed
    setShowReportersIndex(proposalIndex); // Set the current proposal index to show the list
  }
};

  // Fetch report count for a proposal
  const fetchReportCount = useCallback(async (proposalIndex) => {
    try {
      const ethersProvider = new BrowserProvider(provider);
      const signer = await ethersProvider.getSigner();
      const daoContract = new Contract(SETTINGS.weightedDaoContract, WeightedVoting, signer);
      const reportCount = await daoContract.getReportCount(proposalIndex);

      setReportCounts((prevState) => ({
        ...prevState,
        [proposalIndex]: reportCount,
      }));
    } catch (error) {
      console.error('Error fetching report count:', error);
    }
  }, [provider]);

// Function to get flag color and display text for report count
const getFlagTextAndColor = (reportCount, threshold) => {
  const reportCountNumber = Number(reportCount); // Ensure it's a number
  const intensity = Math.min(255, (reportCountNumber / threshold) * 255);
  const color = `rgb(255, ${255 - intensity}, ${255 - intensity})`; // Color shifts to red as reportCount increases
  const text = ` (${reportCountNumber}/${threshold})`;
  return { color, text };
};

// UseEffect to fetch report counts and reported wallets
useEffect(() => {
  proposals.forEach((proposal) => {
    fetchReportCount(proposal.index); // Fetch report counts for each proposal
    fetchReportedWallets(proposal.index); // Fetch reported wallets for each proposal
  });
}, [fetchReportCount, fetchReportedWallets, proposals]);

// Render the report section
const renderReportSection = (proposal) => {
  return !hasAlreadyReported(proposal.index) ? (
    <div className='mt-3'>
      <label className='mb-2'>Report proposal offensive or harmful content:</label>
      <label className='justify'>
        <input
        style={{marginRight:"5px"}}
          type="checkbox"
          checked={!!reportConfirmedMap[proposal.index]}
          onChange={() => handleCheckboxChange(proposal.index)}
        />
          <span className="prop-report">
            I confirm that my report is valid and may result in the delisting of this proposal and/or potential penalties for the submitter's wallet, in accordance with the platform’s 
            <a href="https://docs.waveswaps.com/corporate/terms-and-conditions" target="_blank" className="terms-link"> Terms and Conditions</a>.
          </span>
      </label>
      <button
        className="report-button"
        onClick={() => handleReportProposal(proposal.index)}
        disabled={!reportConfirmedMap[proposal.index] || loadingMap[proposal.index]}
      >
        {loadingMap[proposal.index] ? <span className="spinner"></span> : "Report Proposal"}
      </button>
    </div>
  ) : (
    <div>
      <p>
        <BiFlag
          className="flag-icon"
          style={{ color: getFlagTextAndColor(reportCounts[proposal.index] || 0, reportThreshold).color }} // Set flag color based on report count
          onClick={() => toggleReportersList(proposal.index)} // Toggle reporters list on flag click
        />
        {getFlagTextAndColor(reportCounts[proposal.index] || 0, reportThreshold).text} {/* Display report count */}
      </p>
      {showReportersIndex === proposal.index && (
        <div className="reporters-list">
          <small>Reported by:</small>
          <ul>
            {reportedWalletsMap[proposal.index]?.length > 0 ? (
              reportedWalletsMap[proposal.index].map((wallet, idx) => (
                <li key={idx}>{wallet}</li>
              ))
            ) : (
              <p>No wallets have reported this proposal yet.</p>
            )}
          </ul>
        </div>
      )}
    </div>
  );
};

  // Proposal accordion list
  return (
    <div>
    <h2 className="supplies-header">Weighted Proposals List</h2>

    {/* Filter by category */}
    <div className="filter-container">
      <div className="category-filter">
        <label>Filter:</label>
        <select value={categoryFilter} className="prop-select" onChange={(e) => setCategoryFilter(e.target.value)}>
          <option value="all">All Categories</option>
          {SETTINGS.proposalCategoriesWeighted.map((cat) => (
            <option key={cat} value={cat}>{cat}</option>
          ))}
        </select>
      </div>
      <div className="status-filter">
        <button className="prop-btn hero-button" onClick={() => setFilter('all')}>All</button>
        <button className="prop-btn hero-button" onClick={() => setFilter('finished')}>Finished</button>
      </div>
    </div>
      
      {/* Proposal accordion list */}
      <div className="proposal-list">
    {currentProposals.length > 0 ? (
      currentProposals.map((proposal, index) => (
        <div key={index} className="proposal-item">
          <div className="proposal-header" onClick={() => toggleAccordion(index)}>
            <h3 className="prop-header">{proposal.subject}</h3>
            <p className='d-none d-lg-block'>{proposal.category}</p>
            <p className='d-none d-lg-block'>{proposal.isActive ? 'Open' : 'Finished'}</p>
            <p><BiLike className='vote-yes reaction-buttons' /> {formatUnits(proposal.yesVotes, 0)}</p> {/* Display total Yes votes */}
            <p><BiDislike className='vote-no reaction-buttons' /> {formatUnits(proposal.noVotes, 0)}</p> {/* Display total No votes */}
            {proposal.isActive && <p>{calculateTimeRemaining(proposal.creationTime, index)}</p>}
            
          </div>

          {/* Expandable proposal content (accordion style) */}
          {expandedProposalIndex === index && (
                <div className="row">
                   {/* Right Column: Proposal Content */}
                   <div className="col-12 col-md-6">
                  <br />
                  <h2 className="supplies-header" style={{textAlign:"left"}}>Proposal Content</h2>
                   <br />
                    <p className="justify">{proposal.description}</p>
                  </div>
                  {/* Left Column: Action Buttons and Commands */}
                  <div className="col-12 col-md-12">
                    {proposal.isActive && !hasVotedMap[proposal.index] ? (
                      <WeightedVotingForm
                        provider={provider}
                        contractAddress={SETTINGS.standardDaoContract}
                        tokenAddress={SETTINGS.daoToken}
                        proposal={proposal}
                        onVote={() => {
                          handleVoteSubmit(proposal.index);
                          if (!isProposalExpired(proposal.creationTime)) {
                            handleVoteCallback(proposal.index);
                          }
                        }}
                      />
                    ) : proposal.isActive ? (
                      <p>You have already voted on this proposal.</p>
                    ) : (
                      <p className="prop-end">This proposal has ended and is no longer open for voting.</p>
                    )}

                    {/* Claim Rewards Button (if applicable) */}
                    {!proposal.isActive && renderClaimButtons(proposal)}

                    {/* Voter History Toggle Button */}
                    <button className="history-button" onClick={() => toggleVoterHistory(proposal.index)}>
                      {showVoterHistoryIndex === proposal.index ? 'Hide Voter History' : 'Show Voter History'}
                    </button>

                    {/* Voter History Display */}
                    {showVoterHistoryIndex === proposal.index && (
                      <div className="voter-history">
                        <small>The History of successfully cast votes for this proposal:</small>
                        {voterHistory.length > 0 ? (
                          <ul>
                            {voterHistory.map((voter, idx) => (
                              <li key={idx}>
                                {voter.voterAddress}: {voter.vote}
                              </li>
                            ))}
                          </ul>
                        ) : (
                          <p>No voter history available</p>
                        )}
                      </div>
                    )}
                    {/* Report Proposal Button */}
                    {renderReportSection(proposal)}
                    <br />
                  </div>

                 
                </div>
              )}
            </div>
          ))
        ) : (
          <p>No proposals available</p>
        )}
      </div>


    {/* Render pagination */}
    {filteredProposals.length > proposalsPerPage && renderPagination()}
  </div>
);

};

export default WeightedProposalList;
