import React, { useState, useEffect } from "react";
import { BiSupport, BiLineChart, BiChip } from "react-icons/bi";
import { MdHowToVote, MdOutlineHowToVote } from "react-icons/md";
import { BrowserRouter as Router, Routes, Route, Link } from "react-router-dom";
import Logo from "./logo.svg";
import { Container, Nav, Navbar, NavDropdown } from "react-bootstrap";
import ConnectButton from "./components/ConnectButton";
import "bootstrap/dist/css/bootstrap.min.css";
import "./App.css";
import {
  useWeb3ModalProvider,
  useWeb3ModalAccount,
} from "@web3modal/ethers/react";
import Info from "./components/Info";
import FAQs from "./components/FAQs";
import Footer from "./components/Footer";
import Management from "./components/Management";
import CardMarqueeSection from "./components/CardMarqueeSection";
import RoadmapSection from "./components/RoadmapSection";
import SiteMap from "./components/SiteMap";
import Supplies from "./components/Supplies";
import Tokens from "./components/Tokens";
import StandardVoting from "./components/dao/StandardVoting";
import WeightedVoting from "./components/dao/WeightedVoting";
import {
  BrowserProvider,
  toQuantity,
} from "ethers";
import CryptoBet from "./components/games/crypto-bet/CryptoBet";
import GetonBalances from "./components/GetonBalances";
import GlobalDashboard from "./components/GlobalDashboard";
import SmallMarquee from "./components/SmallMarquee";


function App() {
  const { address, isConnected } = useWeb3ModalAccount();
  const { walletProvider } = useWeb3ModalProvider();
  const [expanded, setExpanded] = useState(false);
  const [networkName, setNetworkName] = useState("");
  const [networkId, setNetworkId] = useState("");
  const [nativeCurrencySymbol, setNativeCurrencySymbol] = useState(""); // State to hold the currency symbol


 

  const handleLinkClick = () => {
    setExpanded(false);
  };

  async function switchNetwork(chainId) {
    if (walletProvider) {
      walletProvider?.removeListener("chainChanged", handleNetworkChange);
      const ethersProvider = new BrowserProvider(walletProvider);
      await ethersProvider.send("wallet_switchEthereumChain", [
        { chainId: toQuantity(chainId) },
      ]);
      const network = await ethersProvider.getNetwork();
      setNetworkName(network.name); // Update network name after switching
      setNetworkId(chainId); // Update network name after switching
      setNativeCurrencySymbol(network.name); // Set native currency symbol
    }
  }

  const fetchNetworkInfo = async () => {
    if (walletProvider) {
      const ethersProvider = new BrowserProvider(walletProvider);
      const network = await ethersProvider.getNetwork();
      setNetworkName(network.name);
      setNetworkId(network.chainId.toString());
      setNativeCurrencySymbol(network.name);
    }
  };

  const handleNetworkChange = async (chainId) => {
    console.log(`Network switched to: ${chainId}`);
    setNetworkId(chainId); // Update the network ID
    await fetchNetworkInfo(); // Fetch network details
  };

  useEffect(() => {
    fetchNetworkInfo();

    // Listen to chain changes
    walletProvider?.on("chainChanged", handleNetworkChange);

    // Clean up the event listener on unmount
    return () => {
      walletProvider?.removeListener("chainChanged", handleNetworkChange);
    };
  }, [walletProvider]);

  return (
    <Router>
      <Navbar expand="lg" className="top-bar" expanded={expanded}>
        <Container>
          <Navbar.Brand
            as={Link}
            to="https://geton.global/"
            onClick={handleLinkClick}
          >
            <img src={Logo} className="globalLogo" alt={"Logo"} />
          </Navbar.Brand>

          <div className="d-block d-lg-none">
            <ConnectButton />
          </div>
          <Navbar.Toggle
            aria-controls="basic-navbar-nav"
            onClick={() => setExpanded(expanded ? false : "expanded")}
            className="px-0"
          />

          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="me-auto mt-lg-0 mt-4 navbar-bkg">
              <Nav.Link
                as={Link}
                to="/"
                className="menuBtn"
                onClick={handleLinkClick}
              >
                {" "}
                Dashboard
              </Nav.Link>
              <Nav.Link
                as={Link}
                to="/game"
                className="menuBtn"
                onClick={handleLinkClick}
              >
                {" "}
                PPG Game
              </Nav.Link>

              <NavDropdown
                title={<span> DAO Governance</span>}
                id="basic-nav-dropdown"
                className="menuBtn dropdown-padding"
              >
                <NavDropdown.Item>
                  <Nav.Link
                    as={Link}
                    to="/dao-standard"
                    onClick={handleLinkClick}
                  >
                    <span>
                      <MdOutlineHowToVote /> Standard Voting
                    </span>
                  </Nav.Link>
                </NavDropdown.Item>
                <NavDropdown.Item>
                  <Nav.Link
                    as={Link}
                    to="/dao-weighted"
                    onClick={handleLinkClick}
                  >
                    <span>
                      <MdHowToVote /> Weighted Voting
                    </span>
                  </Nav.Link>
                </NavDropdown.Item>
              </NavDropdown>

              <NavDropdown
                title={<span>Info</span>}
                id="basic-nav-dropdown"
                className="menuBtn dropdown-padding"
              >
                <NavDropdown.Item>
                  <Nav.Link as={Link} to="/tokens" onClick={handleLinkClick}>
                    <span>
                      <BiLineChart /> Tokens
                    </span>
                  </Nav.Link>
                </NavDropdown.Item>
                <NavDropdown.Item>
                  <Nav.Link as={Link} to="/supplies" onClick={handleLinkClick}>
                    <span>
                      <BiLineChart /> Multichain Supplies
                    </span>
                  </Nav.Link>
                </NavDropdown.Item>

                <NavDropdown.Item>
                  <Nav.Link as={Link} to="/FAQs" onClick={handleLinkClick}>
                    <span>
                      <BiSupport /> FAQs
                    </span>
                  </Nav.Link>
                </NavDropdown.Item>
              </NavDropdown>
            </Nav>
          </Navbar.Collapse>

          <div className="d-none d-lg-block">
            <ConnectButton />
          </div>
        </Container>
      </Navbar>
      <SmallMarquee />

      <div className="pb-5">
 
        <Routes>
          <Route
            path="/geton-balances"
            element={
              <Container className="pb-5">
               
                <GetonBalances
                  provider={walletProvider}
                  networkSymbol={nativeCurrencySymbol}
                  address={address}
                  isConnected={isConnected}
                  networkId={networkId}
                  networkName={networkName}
                  switchNetwork={switchNetwork}
                />
              </Container>
            }
          />
          <Route
            path="/"
            element={
              <Container className="pb-5">
                <GlobalDashboard
                  provider={walletProvider}
                  networkSymbol={nativeCurrencySymbol}
                  address={address}
                  isConnected={isConnected}
                  networkId={networkId}
                  networkName={networkName}
                  switchNetwork={switchNetwork}
                />
              </Container>
            }
          />

         
          <Route
            path="/game"
            element={
              <Container className="pb-5">
                <CryptoBet
                  provider={walletProvider}
                  networkSymbol={nativeCurrencySymbol}
                  account={address}
                  isConnected={isConnected}
                  networkId={networkId}
                  networkName={networkName}
                  switchNetwork={switchNetwork}
                />
              </Container>
            }
          />
          <Route
            path="/FAQs"
            element={
              <Container className="pb-5">
                <FAQs />
              </Container>
            }
          />
          <Route
            path="/supplies"
            element={
              <Container className="pb-5">
                <Supplies />
              </Container>
            }
          />

          <Route
            path="/info"
            element={
              <Container className="pb-5">
                <Info provider={walletProvider} />
              </Container>
            }
          />
          <Route
            path="/tokens"
            element={
              <Container className="pb-5">
                <br />
                <br />
                <Tokens />
              </Container>
            }
          />
        
          <Route
            path="/management"
            element={
              <Container className="pb-5">
                <Management
                  provider={walletProvider}
                  account={address}
                  isConnected={isConnected}
                />
              </Container>
            }
          />
          <Route
            path="/site-map"
            element={
              <Container className="pb-5">
                <SiteMap
                  provider={walletProvider}
                  account={address}
                  isConnected={isConnected}
                />
              </Container>
            }
          />

          <Route
            path="/roadmap"
            element={
              <Container className="pb-5">
                <div style={{ marginBottom: "250px" }}>
                  <RoadmapSection
                    provider={walletProvider}
                    account={address}
                    isConnected={isConnected}
                    networkId={networkId}
                    networkName={networkName}
                  />
                </div>
              </Container>
            }
          />
          <Route
            path="/liquidity-engines"
            element={
              <Container className="pb-5">
                <div style={{ marginBottom: "250px" }}>
                  <CardMarqueeSection
                    provider={walletProvider}
                    account={address}
                    isConnected={isConnected}
                    networkId={networkId}
                    networkName={networkName}
                  />
                </div>
              </Container>
            }
          />
          <Route
            path="/dao-standard"
            element={
              <Container className="pb-5">
                <br />
                <StandardVoting
                  provider={walletProvider}
                  address={address}
                  isConnected={isConnected}
                />
              </Container>
            }
          />
          <Route
            path="/dao-weighted"
            element={
              <Container className="pb-5">
                <br />
                <WeightedVoting
                  provider={walletProvider}
                  address={address}
                  isConnected={isConnected}
                />
              </Container>
            }
          />
          <Route
            path="/supplies"
            element={
              <Container className="pb-5">
                <Supplies />
              </Container>
            }
          />
        </Routes>
      </div>
      <Footer />
    </Router>
  );
}

export default App;
