// src/components/SmallMarquee.js
import React from 'react';


const notifications = [
  { icon: "https://wave.waveswaps.com/marquee/spotlight.svg", text: "", url: "https://docs.waveswaps.com/ws-official/historical-evolution/geton-global/crypto-spotlight" },

  { icon: "https://wave.waveswaps.com/marquee/larp.jpg", text: "$LARP", url: "https://dexscreener.com/base/0xeb8862bBcc1465f362A6ceED7224C82D18BFC7a2" },

  { icon: "https://wave.waveswaps.com/marquee/spotlight.svg", text: "", url: "https://docs.waveswaps.com/ws-official/historical-evolution/geton-global/crypto-spotlight" },

  { icon: "https://wave.waveswaps.com/marquee/lore.png", text: "$LORE", url: "https://dexscreener.com/base/0x6be4c81f670bdc1ce7957cfeaea352a1fca07b02" },
  
  { icon: "https://wave.waveswaps.com/marquee/spotlight.svg", text: "", url: "https://docs.waveswaps.com/ws-official/historical-evolution/geton-global/crypto-spotlight" },

  { icon: "https://wave.waveswaps.com/marquee/keycat.jpg", text: "$KEYCAT", url: "https://dexscreener.com/base/0x377FeeeD4820B3B28D1ab429509e7A0789824fCA" },

  { icon: "https://wave.waveswaps.com/marquee/spotlight.svg", text: "", url: "https://docs.waveswaps.com/ws-official/historical-evolution/geton-global/crypto-spotlight" },

  { icon: "https://wave.waveswaps.com/marquee/luminius.webp", text: "luminous", url: "https://dexscreener.com/base/0x6d6391B9bD02Eefa00FA711fB1Cb828A6471d283" },

  { icon: "https://wave.waveswaps.com/marquee/spotlight.svg", text: "", url: "https://docs.waveswaps.com/ws-official/historical-evolution/geton-global/crypto-spotlight" },

  { icon: "https://wave.waveswaps.com/marquee/luna.png", text: "Luna", url: "https://dexscreener.com/base/0xa8e64fb120ce8796594670bae72279c8aa1e5359" },

  { icon: "https://wave.waveswaps.com/marquee/spotlight.svg", text: "", url: "https://docs.waveswaps.com/ws-official/historical-evolution/geton-global/crypto-spotlight" },

  { icon: "https://wave.waveswaps.com/marquee/skimaskdog.jpg", text: "SkiMaskDog", url: "https://yourwebsite.com/faq" },

  { icon: "https://wave.waveswaps.com/marquee/spotlight.svg", text: "", url: "https://docs.waveswaps.com/ws-official/historical-evolution/geton-global/crypto-spotlight" },

  { icon: "https://wave.waveswaps.com/marquee/keycat.jpg", text: "$KEYCAT", url: "https://dexscreener.com/base/0x377FeeeD4820B3B28D1ab429509e7A0789824fCA" },

  { icon: "https://wave.waveswaps.com/marquee/spotlight.svg", text: "", url: "https://docs.waveswaps.com/ws-official/historical-evolution/geton-global/crypto-spotlight" },

  { icon: "https://wave.waveswaps.com/marquee/lore.png", text: "$LORE", url: "https://dexscreener.com/base/0x6be4c81f670bdc1ce7957cfeaea352a1fca07b02" },

  { icon: "https://wave.waveswaps.com/marquee/spotlight.svg", text: "", url: "https://docs.waveswaps.com/ws-official/historical-evolution/geton-global/crypto-spotlight" },

  { icon: "https://wave.waveswaps.com/marquee/larp.jpg", text: "$LARP", url: "https://dexscreener.com/base/0xeb8862bBcc1465f362A6ceED7224C82D18BFC7a2" },

  { icon: "https://wave.waveswaps.com/marquee/spotlight.svg", text: "", url: "https://docs.waveswaps.com/ws-official/historical-evolution/geton-global/crypto-spotlight" },

  { icon: "https://wave.waveswaps.com/marquee/luminius.webp", text: "luminous", url: "https://dexscreener.com/base/0x6d6391B9bD02Eefa00FA711fB1Cb828A6471d283" },

  { icon: "https://wave.waveswaps.com/marquee/spotlight.svg", text: "", url: "https://docs.waveswaps.com/ws-official/historical-evolution/geton-global/crypto-spotlight" },

  { icon: "https://wave.waveswaps.com/marquee/luna.png", text: "Luna", url: "https://dexscreener.com/base/0xa8e64fb120ce8796594670bae72279c8aa1e5359" },

  { icon: "https://wave.waveswaps.com/marquee/spotlight.svg", text: "", url: "https://docs.waveswaps.com/ws-official/historical-evolution/geton-global/crypto-spotlight" },

  { icon: "https://wave.waveswaps.com/marquee/skimaskdog.jpg", text: "SkiMaskDog", url: "https://yourwebsite.com/faq" },

  { icon: "https://wave.waveswaps.com/marquee/spotlight.svg", text: "", url: "https://docs.waveswaps.com/ws-official/historical-evolution/geton-global/crypto-spotlight" },

    { icon: "https://wave.waveswaps.com/marquee/keycat.jpg", text: "$KEYCAT", url: "https://dexscreener.com/base/0x377FeeeD4820B3B28D1ab429509e7A0789824fCA" },

    { icon: "https://wave.waveswaps.com/marquee/spotlight.svg", text: "", url: "https://docs.waveswaps.com/ws-official/historical-evolution/geton-global/crypto-spotlight" },

    { icon: "https://wave.waveswaps.com/marquee/lore.png", text: "$LORE", url: "https://dexscreener.com/base/0x6be4c81f670bdc1ce7957cfeaea352a1fca07b02" },

  { icon: "https://wave.waveswaps.com/marquee/spotlight.svg", text: "", url: "https://docs.waveswaps.com/ws-official/historical-evolution/geton-global/crypto-spotlight" },

  { icon: "https://wave.waveswaps.com/marquee/larp.jpg", text: "$LARP", url: "https://dexscreener.com/base/0xeb8862bBcc1465f362A6ceED7224C82D18BFC7a2" },

  { icon: "https://wave.waveswaps.com/marquee/spotlight.svg", text: "", url: "https://docs.waveswaps.com/ws-official/historical-evolution/geton-global/crypto-spotlight" },

  { icon: "https://wave.waveswaps.com/marquee/luminius.webp", text: "luminous", url: "https://dexscreener.com/base/0x717358A47AC99f3Cd233e723be331756b3951164" },

  { icon: "https://wave.waveswaps.com/marquee/spotlight.svg", text: "", url: "https://docs.waveswaps.com/ws-official/historical-evolution/geton-global/crypto-spotlight" },

  { icon: "https://wave.waveswaps.com/marquee/luna.png", text: "Luna", url: "https://dexscreener.com/base/0xa8e64fb120ce8796594670bae72279c8aa1e5359" },

  { icon: "https://wave.waveswaps.com/marquee/spotlight.svg", text: "", url: "https://docs.waveswaps.com/ws-official/historical-evolution/geton-global/crypto-spotlight" },

  { icon: "https://wave.waveswaps.com/marquee/skimaskdog.jpg", text: "SkiMaskDog", url: "https://dexscreener.com/base/0x6d6391B9bD02Eefa00FA711fB1Cb828A6471d283" },

  { icon: "https://wave.waveswaps.com/marquee/spotlight.svg", text: "", url: "https://docs.waveswaps.com/ws-official/historical-evolution/geton-global/crypto-spotlight" },

  { icon: "https://wave.waveswaps.com/marquee/keycat.jpg", text: "$KEYCAT", url: "https://dexscreener.com/base/0x377FeeeD4820B3B28D1ab429509e7A0789824fCA" },

  { icon: "https://wave.waveswaps.com/marquee/spotlight.svg", text: "", url: "https://docs.waveswaps.com/ws-official/historical-evolution/geton-global/crypto-spotlight" },

  { icon: "https://wave.waveswaps.com/marquee/lore.png", text: "$LORE", url: "https://dexscreener.com/base/0x6be4c81f670bdc1ce7957cfeaea352a1fca07b02" },

  { icon: "https://wave.waveswaps.com/marquee/spotlight.svg", text: "", url: "https://docs.waveswaps.com/ws-official/historical-evolution/geton-global/crypto-spotlight" },

  { icon: "https://wave.waveswaps.com/marquee/larp.jpg", text: "$LARP", url: "https://dexscreener.com/base/0xeb8862bBcc1465f362A6ceED7224C82D18BFC7a2" },

  { icon: "https://wave.waveswaps.com/marquee/spotlight.svg", text: "", url: "https://docs.waveswaps.com/ws-official/historical-evolution/geton-global/crypto-spotlight" },

  { icon: "https://wave.waveswaps.com/marquee/luminius.webp", text: "luminous", url: "https://dexscreener.com/base/0x6d6391B9bD02Eefa00FA711fB1Cb828A6471d283" },

  { icon: "https://wave.waveswaps.com/marquee/spotlight.svg", text: "", url: "https://docs.waveswaps.com/ws-official/historical-evolution/geton-global/crypto-spotlight" },

  { icon: "https://wave.waveswaps.com/marquee/luna.png", text: "Luna", url: "https://dexscreener.com/base/0xa8e64fb120ce8796594670bae72279c8aa1e5359" },

  { icon: "https://wave.waveswaps.com/marquee/spotlight.svg", text: "", url: "https://docs.waveswaps.com/ws-official/historical-evolution/geton-global/crypto-spotlight" },

  { icon: "https://wave.waveswaps.com/marquee/skimaskdog.jpg", text: "SkiMaskDog", url: "https://yourwebsite.com/faq" },

  { icon: "https://wave.waveswaps.com/marquee/spotlight.svg", text: "", url: "https://docs.waveswaps.com/ws-official/historical-evolution/geton-global/crypto-spotlight" },

  { icon: "https://wave.waveswaps.com/marquee/keycat.jpg", text: "$KEYCAT", url: "https://dexscreener.com/base/0x377FeeeD4820B3B28D1ab429509e7A0789824fCA" },

  { icon: "https://wave.waveswaps.com/marquee/spotlight.svg", text: "", url: "https://docs.waveswaps.com/ws-official/historical-evolution/geton-global/crypto-spotlight" },

  { icon: "https://wave.waveswaps.com/marquee/lore.png", text: "$LORE", url: "https://dexscreener.com/base/0x6be4c81f670bdc1ce7957cfeaea352a1fca07b02" },

  { icon: "https://wave.waveswaps.com/marquee/spotlight.svg", text: "", url: "https://docs.waveswaps.com/ws-official/historical-evolution/geton-global/crypto-spotlight" },

  { icon: "https://wave.waveswaps.com/marquee/larp.jpg", text: "$LARP", url: "https://dexscreener.com/base/0xeb8862bBcc1465f362A6ceED7224C82D18BFC7a2" },

  { icon: "https://wave.waveswaps.com/marquee/spotlight.svg", text: "", url: "https://docs.waveswaps.com/ws-official/historical-evolution/geton-global/crypto-spotlight" },

  { icon: "https://wave.waveswaps.com/marquee/luminius.webp", text: "luminous", url: "https://dexscreener.com/base/0x6d6391B9bD02Eefa00FA711fB1Cb828A6471d283" },

  { icon: "https://wave.waveswaps.com/marquee/spotlight.svg", text: "", url: "https://docs.waveswaps.com/ws-official/historical-evolution/geton-global/crypto-spotlight" },

  { icon: "https://wave.waveswaps.com/marquee/luna.png", text: "Luna", url: "https://dexscreener.com/base/0xa8e64fb120ce8796594670bae72279c8aa1e5359" },

  { icon: "https://wave.waveswaps.com/marquee/spotlight.svg", text: "", url: "https://docs.waveswaps.com/ws-official/historical-evolution/geton-global/crypto-spotlight" },

  { icon: "https://wave.waveswaps.com/marquee/skimaskdog.jpg", text: "SkiMaskDog", url: "https://yourwebsite.com/faq" },

  { icon: "https://wave.waveswaps.com/marquee/spotlight.svg", text: "", url: "https://docs.waveswaps.com/ws-official/historical-evolution/geton-global/crypto-spotlight" },

    { icon: "https://wave.waveswaps.com/marquee/keycat.jpg", text: "$KEYCAT", url: "https://dexscreener.com/base/0x377FeeeD4820B3B28D1ab429509e7A0789824fCA" },

    { icon: "https://wave.waveswaps.com/marquee/spotlight.svg", text: "", url: "https://docs.waveswaps.com/ws-official/historical-evolution/geton-global/crypto-spotlight" },

    { icon: "https://wave.waveswaps.com/marquee/lore.png", text: "$LORE", url: "https://dexscreener.com/base/0x6be4c81f670bdc1ce7957cfeaea352a1fca07b02" },

    { icon: "https://wave.waveswaps.com/marquee/spotlight.svg", text: "", url: "https://docs.waveswaps.com/ws-official/historical-evolution/geton-global/crypto-spotlight" },

    { icon: "https://wave.waveswaps.com/marquee/larp.jpg", text: "$LARP", url: "https://dexscreener.com/base/0xeb8862bBcc1465f362A6ceED7224C82D18BFC7a2" },

  { icon: "https://wave.waveswaps.com/marquee/spotlight.svg", text: "", url: "https://docs.waveswaps.com/ws-official/historical-evolution/geton-global/crypto-spotlight" },

  { icon: "https://wave.waveswaps.com/marquee/luminius.webp", text: "luminous", url: "https://dexscreener.com/base/0x717358A47AC99f3Cd233e723be331756b3951164" },

  { icon: "https://wave.waveswaps.com/marquee/spotlight.svg", text: "", url: "https://docs.waveswaps.com/ws-official/historical-evolution/geton-global/crypto-spotlight" },

  { icon: "https://wave.waveswaps.com/marquee/luna.png", text: "Luna", url: "https://dexscreener.com/base/0xa8e64fb120ce8796594670bae72279c8aa1e5359" },

  { icon: "https://wave.waveswaps.com/marquee/spotlight.svg", text: "", url: "https://docs.waveswaps.com/ws-official/historical-evolution/geton-global/crypto-spotlight" },

  { icon: "https://wave.waveswaps.com/marquee/skimaskdog.jpg", text: "SkiMaskDog", url: "https://dexscreener.com/base/0x6d6391B9bD02Eefa00FA711fB1Cb828A6471d283" },

  { icon: "https://wave.waveswaps.com/marquee/spotlight.svg", text: "", url: "https://docs.waveswaps.com/ws-official/historical-evolution/geton-global/crypto-spotlight" },

];

const SmallMarquee = () => {
  return (
    <div className="marquee-container-small">
      <div className="marquee-content-small">
        {notifications.map((notification, index) => (
          <a
            key={`first-${index}`}
            href={notification.url}
            target="_blank"
            rel="noopener noreferrer"
            className="marquee-link"
          >
            <img src={notification.icon} alt="" className="marquee-icon" />
            {notification.text}
          </a>
        ))}
        {notifications.map((notification, index) => (
          <a
            key={`second-${index}`}
            href={notification.url}
            target="_blank"
            rel="noopener noreferrer"
            className="marquee-link"
          >
            <img src={notification.icon} alt="" className="marquee-icon" />
            {notification.text}
          </a>
        ))}
      </div>
    </div>
  );
};


export default SmallMarquee;
