import React, { useState } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Modal, Button } from 'react-bootstrap';
import { PiTrademarkRegisteredLight } from "react-icons/pi";

import { BiInfoCircle, BiBox, BiCoin, BiCalendar, BiCheckCircle } from 'react-icons/bi';

const ReferralButton = () => {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  return (
    <>
      <Button
        variant="danger"
        onClick={handleShow}
        className='refBtnHelp round'
    
      >
        <PiTrademarkRegisteredLight className='btn-info' />
      </Button>

      <Modal show={show} onHide={handleClose} centered>
  <Modal.Header style={{ display: "block" }}>
    <Modal.Title><center>Referral Code Required</center></Modal.Title>
  </Modal.Header>
  <Modal.Body className="text-center">
    <p>To purchase a package, you'll need a referral code.</p>
    <p>If you don't have one, you can use 
      <b style={{ padding: "10px", display: "block" }}>GLOBAL</b> 
      as a default code. 
</p>
  </Modal.Body>
  <Modal.Footer>
    <Button variant="secondary" className="buton" onClick={handleClose}>
      Close
    </Button>
  </Modal.Footer>
</Modal>

    </>
  );
};

export default ReferralButton;
