import React, { useState, useEffect } from 'react';
import { BrowserProvider, Contract, parseUnits, formatUnits } from "ethers";

const CONTRACT_ADDRESS = '0xc9650CEEB0A8a8467794fd136516a2921CAeF583';
const CONTRACT_ABI = [
  "function claimDAOToken(uint256 amount) public",
  "function withdrawDAOToken() public",
  "function userClaims(address user) view returns (uint256 totalClaimed, uint256 withdrawn, uint256 startTime)"
];

const DAOTokenComponent = ({ provider, account, daoClaimAmount }) => {
  const [isClaimed, setIsClaimed] = useState(false);
  const [loading, setLoading] = useState(false);
  const [claimAmount, setClaimAmount] = useState(0);
  const [withdrawnAmount, setWithdrawnAmount] = useState(0);
  const [nextWithdrawalTime, setNextWithdrawalTime] = useState(null);
  const [timeRemaining, setTimeRemaining] = useState(null);

  useEffect(() => {
    checkClaimStatus();
  }, []);

  useEffect(() => {
    // Start the countdown if there's a valid next withdrawal time
    if (nextWithdrawalTime) {
      const interval = setInterval(() => {
        const currentTime = Math.floor(Date.now() / 1000); // current time in seconds
        const remaining = nextWithdrawalTime - currentTime;
        setTimeRemaining(remaining > 0 ? remaining : 0);
        
        // Clear the interval if the countdown is done
        if (remaining <= 0) {
          clearInterval(interval);
        }
      }, 1000);
      return () => clearInterval(interval);
    }
  }, [nextWithdrawalTime]);

  const checkClaimStatus = async () => {
    try {
      const ethersProvider = new BrowserProvider(provider);
      const signer = await ethersProvider.getSigner();
      const contract = new Contract(CONTRACT_ADDRESS, CONTRACT_ABI, signer);
  
      const userClaim = await contract.userClaims(account);
      const hasClaimed = parseInt(userClaim.totalClaimed.toString()) > 0;
      
      setIsClaimed(hasClaimed);
      setClaimAmount(parseInt(userClaim.totalClaimed.toString()));
      setWithdrawnAmount(parseInt(userClaim.withdrawn.toString()));
  
      if (hasClaimed) {
        const ONE_DAY_IN_SECONDS = 24 * 60 * 60;
        const lastWithdrawalTime = parseInt(userClaim.startTime.toString()) + Math.floor(parseInt(userClaim.withdrawn.toString()) / (parseInt(userClaim.totalClaimed.toString()) / 100)) * ONE_DAY_IN_SECONDS;
        setNextWithdrawalTime(lastWithdrawalTime + ONE_DAY_IN_SECONDS);
      }
    } catch (error) {
      console.error("Error checking claim status:", error);
    }
  };
  

  const claimTokens = async () => {
    setLoading(true);
    const ethersProvider = new BrowserProvider(provider);
    const signer = await ethersProvider.getSigner();
    const contract = new Contract(CONTRACT_ADDRESS, CONTRACT_ABI, signer);

    try {
      const amountToClaim = parseUnits(daoClaimAmount.toString(), 0);
      const tx = await contract.claimDAOToken(amountToClaim);
      await tx.wait();
      setIsClaimed(true);
      setClaimAmount(amountToClaim);
    } catch (error) {
      console.error("Error claiming tokens:", error);
    }
    setLoading(false);
  };

  const withdrawTokens = async () => {
    setLoading(true);
    const ethersProvider = new BrowserProvider(provider);
    const signer = await ethersProvider.getSigner();
    const contract = new Contract(CONTRACT_ADDRESS, CONTRACT_ABI, signer);

    try {
      const tx = await contract.withdrawDAOToken();
      await tx.wait();
      checkClaimStatus();
    } catch (error) {
      console.error("Error withdrawing tokens:", error);
    }
    setLoading(false);
  };

  const formatTimestamp = (timestamp) => {
    const date = new Date(timestamp * 1000);
    return date.toLocaleString();
  };

  const formatTimeRemaining = (seconds) => {
    const hours = Math.floor((seconds % (24 * 3600)) / 3600);
    const minutes = Math.floor((seconds % 3600) / 60);
    const secs = seconds % 60;
    return `${hours}h ${minutes}m ${secs}s`;
  };

  return (
<div className="container" style={{ padding: '20px', textAlign: 'center' }}>
      
      {account ? (
        <div className="row align-items-center">
<small className='mb-4'>
  Users can claim their DAO tokens daily over a 100-day period, calculated based on their token balance multiplied by the ecosystem value, with 1 DAO token equal to 1 USD. 
  </small>
          {!isClaimed ? (
            <div className="col">
              <p>You can claim your DAO tokens. All tokens can be claimed over a period of 100 days. DAO tokens are used for voting within the DAO.</p>
              <button onClick={claimTokens} className="btn btn-primary">
             
              <img src="https://tokens.waveswaps.com/images/tokens/DAO.svg"  width="23" height="auto" /> Claim {parseInt(daoClaimAmount).toLocaleString()} DAO Tokens
              </button>
            </div>
          ) : (
            <>
              <div className="col">
                <p><strong>Claimed:</strong><img src="https://tokens.waveswaps.com/images/tokens/DAO.svg" style={{marginLeft:"5px", marginRight:"5px"}} width="20" height="auto" /> 
                 {parseInt(formatUnits(claimAmount, 0)).toLocaleString()} DAO</p>
              </div>
              <div className="col">
                <p><strong>Withdrawn:</strong><img src="https://tokens.waveswaps.com/images/tokens/DAO.svg"  width="20" style={{marginLeft:"5px", marginRight:"5px"}} height="auto" /> 
                 {parseInt(formatUnits(withdrawnAmount, 0)).toLocaleString()} DAO</p>
              </div>
   
              <div className="col">
                {timeRemaining && timeRemaining > 0 ? (
                  <p><strong>Next Withdrawal:</strong> {formatTimeRemaining(timeRemaining)}</p>
                ) : (
                  <button onClick={withdrawTokens} className="btn btn-success">
                    <img src="https://tokens.waveswaps.com/images/tokens/DAO.svg"  width="23" height="auto" /> Withdraw DAO
                  </button>
                )}
              </div>
            </>
          )}
        </div>
      ) : (
        <p>Please connect your wallet</p>
      )}
    </div>
  );
};

export default DAOTokenComponent;
