import React, { useState, useEffect } from 'react';
import { Button, Accordion, Image } from 'react-bootstrap';
import copyImg from '../copy.png';
import StripeLoaderImg from '../stripe_loader.svg';
import BettingContractABI from '../abis/BettingContractABI';
import SETTINGS from '../SETTINGS';
import { BrowserProvider, Contract } from 'ethers'
import { useNavigate } from 'react-router-dom';
import { BiLike } from 'react-icons/bi';

const contractAddress = "0xeBcb2Bcce3A9302f5CF2583BF501c850703B9D3c";
const RefferalComponent = ({ provider, isConnected }) => {
    const [loading, setLoading] = useState(true);
    const [copying, setCopying] = useState(false);
    const [setTxMessage] = useState('');
    const [refferalCode, setRefferalCode] = useState('');
    const [packageLevel, setPackageLevel] = useState();
    const [userPercent, setUserPercent] = useState(10);
    const [userCode, setUserCode] = useState('');
    const [levelModelUrl, setLevelModelUrl] = useState("https://node.waveswaps.com/");
    const trunAddress = `${SETTINGS.collection.slice(0, 4)}...${SETTINGS.collection.slice(-1 * (4))}`;
    const navigate = useNavigate();
    const openCraft = () => {
        navigate('/craft');
    }
    const openBuyNode = () => {
        navigate('/store');
    }
    const copyCode = () => {
        setCopying(true);
        navigator.clipboard.writeText(userCode);
        setTimeout(() => setCopying(false), 2000);
    }
    const _setReffCode = async (code) => {
        setRefferalCode(code);
    };

    const getUserCode = async () => {
       

        const ethersProvider = new BrowserProvider(provider);
          const signer = await ethersProvider.getSigner();
          const contract = new Contract(contractAddress, BettingContractABI, signer);
          const activePackage = await contract.packageExpiry(signer.address);
          setPackageLevel(activePackage);
       
        // Call the buy function on the NFT contract
        // Since we're only working with USDT, we don't need to send ETH value
        const code = await contract.inviteCodeForAddress(signer.address);
   
        setUserCode(code);
        setLoading(false);
    };


    const createCode = async () => {
        const ethersProvider = new BrowserProvider(provider);
        const signer = await ethersProvider.getSigner();
        try {
            setLoading(true);
            const contract = new Contract(
                contractAddress,
                BettingContractABI,
                signer
            );
            // Connect to the NFT contract and buy the NFT
            if (refferalCode.length <= 5) {
                alert("Should be longer than 5 characters.");
                setLoading(false);
                return;
            }
            const codeCheck = await contract.inviteCode(refferalCode.toUpperCase());
            if (codeCheck != "0x0000000000000000000000000000000000000000") {
                alert("Code is taken");
                setLoading(false);
                return;
            }
            // Call the buy function on the NFT contract
            // Since we're only working with USDT, we don't need to send ETH value
            const refTx = await contract.addRefferal(refferalCode.toUpperCase());
            await refTx.wait();
        }
        catch (error) {
            console.error("Error creating code:", error);
        }
        finally {

            getUserCode();
            // Optionally reset the transaction message after a delay
            setTimeout(() => setLoading(false), 2000);
        }
    };
    useEffect(() => {
        if (isConnected) {
            getUserCode();
        } else {
            setLoading(false);
        }
    }, [isConnected, provider]);


    if (loading) {
        return (
            <div className="pokemon-card">
                <div className="text-center">
                    <center>
                        <img src={StripeLoaderImg} style={{ width: "40%" }} />
                    </center>
                </div>
            </div>
        );
    }


    return (
    <Accordion defaultActiveKey="1" className='refAccordion'>
        <Accordion.Item eventKey="2" className="my-0">
            <Accordion.Header>Referral</Accordion.Header>
            <Accordion.Body>
                <div className="text-center mb-2">
                {packageLevel == 0 && userCode != "" ? (
                    <p>Buy a package to unlock Refferal.</p>
                ):(
                        <>
                            {userCode === "" ? (
                                <div className=''>
                                    <div className='input-group' style={{ marginBottom: "14px" }}>
                                        <label className='mb-1'>Create referral code</label>
                                        <input
                                            type="text"
                                            value={refferalCode}
                                            onChange={(e) => _setReffCode(e.target.value)}
                                            placeholder="Referral Code"
                                            className='custom-select'
                                        />
                                    </div>
                                    <Button className='buton buton buton-secondary d-lg-block' onClick={createCode}>CREATE</Button>
                                </div>
                            ) : (
                                <>
                                    <p className='small mt-2'>** Invite others and get rewarded when they purchase a QWRLD!</p>
                                    <h6 className='mt-1'>Referral percent: {userPercent}%</h6>
                                    {copying ? (
                                        <Button variant="primary mt-3 d-flex justify-content-center align-items-center" className='buton'>
                                            <BiLike style={{ fontSize: "20px" }} />
                                        </Button>
                                    ) : (
                                        <Button variant="primary mt-3" onClick={copyCode} className='buton'>
                                            <img src={copyImg} alt="" style={{ width: "13px", marginRight: "5px", filter: "", opacity: "1" }} />
                                            {userCode}
                                        </Button>
                                    )}
                                </>
                            )}
                        </>
                    )}
                </div>
            </Accordion.Body>
        </Accordion.Item>
    </Accordion>

    );
};

export default RefferalComponent;
