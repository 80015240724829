import React, { useEffect, useState } from 'react';
import { BiCopy } from "react-icons/bi";
import Table from 'react-bootstrap/Table';
import SETTINGS_GETON from '../SETTINGS_GETON';  // Ensure the path to SETTINGS_GETON is correct
import MatrixEffect from './MatrixEffect';
import DAOTokenComponent from './DAOTokenComponent';
import TokenWithdrawComponent from './TokenWithdrawComponent';
import { Spinner } from 'react-bootstrap';


const GetonBalances = ({ provider, address, isConnected, networkId, switchNetwork }) => {
  const [assetData, setAssetData] = useState({});  // State to store data for assets
  const [loading, setLoading] = useState(true);    // Loading state
  const [email, setEmail] = useState('');
  const [code, setCode] = useState('');
  const [isCodeSent, setIsCodeSent] = useState(false);
  const [balances, setBalances] = useState(null);
  const [error, setError] = useState('');
  const [daoTokensToClaim, setDaoTokenToClaim] = useState(0);
  const [isAddressLinked, setIsAddressLinked] = useState(false);
  const tokenIcons = ["PIPL", "GETON", "PRON", "CBDX", "GROSH", "LOS", "APOD", "CARB", "EGROSH"];

  const prices = {
    "PIPL": 0.000003,
    "GETON": 0.00000028,
    "PRON":  0.00000002,
    "CBDX": 0.00006644,
    "GROSH": 0.00000563,
    "LOS": 0.00042172,
    "APOD": 0.00000563,
    "CARB": 0.00001309,
    "EGROSH": 0.00000563,
  };

  const daoPrices = {
    "PIPL": 1.82,
    "GETON": 60,
    "PRON": 960,
    "CBDX": 8,
    "GROSH": 1.2,
    "LOS": 24,
    "APOD": 1.2,
    "CARB": 18,
    "EGROSH": 1.2,
  };

  const decimals = {
    "PIPL": 8,
    "GETON": 8,
    "PRON": 8,
    "CBDX": 8,
    "GROSH": 8,
    "LOS": 8,
    "APOD": 8,
    "CARB": 8,
    "EGROSH": 8,
  };


  const delay = (ms) => new Promise(resolve => setTimeout(resolve, ms));


  const handleGetBalances = async () => {
    setError(null);
    setBalances(null);

    try {
        const response = await fetch('https://backend.geton.global/get_balances.php', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
            },
            body: new URLSearchParams({ eth_address: address }),
        });

        const data = await response.json();
        let fullDaoAmount = 0;
       for(let i=0;i<tokenIcons.length;i++){
        const am = (parseFloat(data.balances[tokenIcons[i]]) / 10 ** decimals[tokenIcons[i]]) * daoPrices[tokenIcons[i]];

        fullDaoAmount += am;
       }
       setDaoTokenToClaim(fullDaoAmount.toFixed(0));
        if (data.status === 'success') {
          setBalances(data.balances);
        } else {
            setError(data.message);
        }
    } catch (error) {
        setError("An error occurred while fetching balance data.");
    }
};


  const handleSendCode = async () => {
    try {
      const response = await fetch('https://backend.geton.global/generate_code.php', {
        method: 'POST',
        headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
        body: new URLSearchParams({ email })
      });
      const result = await response.json();
      if (result.status === 'success') {
        setIsCodeSent(true);
        setError('');
      } else {
        setError(result.message);
        setIsCodeSent(true);
      }
    } catch (error) {
     
      setIsCodeSent(true);
    }
  };
  const handleVerifyCode = async () => {
    try {
      const response = await fetch('https://backend.geton.global/verify_code.php', {
        method: 'POST',
        headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
        body: new URLSearchParams({ email, code, eth_address: address }) // Assuming `address` holds the Ethereum address
      });
      
      const result = await response.json();

      if (result.status === 'success') {
        setBalances(result.balances);
        
        setError('');
      } else {
        setError(result.message);
      }
    } catch (error) {
      setError('Failed to verify code');
    }
  };


  const getApiUrlForNetwork = (network, tokenAddress, walletAddress, apiKey) => {
    switch (network) {
      case 'Polygon Mainnet':
        return `https://api.polygonscan.com/api?module=account&action=tokenbalance&contractaddress=${tokenAddress}&address=${walletAddress}&tag=latest&apikey=${apiKey}`;
      case 'BNB Smart Chain':
        return `https://api.bscscan.com/api?module=account&action=tokenbalance&contractaddress=${tokenAddress}&address=${walletAddress}&tag=latest&apikey=${apiKey}`;
      default:
        console.error('Unsupported network:', network);
        return null;
    }
  };

  const fetchPrice = async (asset, key) => {
    const { tokenAddress1, tokenAddress2, walletAddress, tokenDecimals1, tokenDecimals2, apiKey, network } = asset;
    const apiUrl1 = getApiUrlForNetwork(network, tokenAddress1, walletAddress, apiKey);
    const apiUrl2 = getApiUrlForNetwork(network, tokenAddress2, walletAddress, apiKey);

    if (!apiUrl1 || !apiUrl2) return { price: 'Error' };

    try {
      const [response1, response2] = await Promise.all([fetch(apiUrl1), fetch(apiUrl2)]);
      const data1 = await response1.json();
      const data2 = await response2.json();
      const balance1 = data1.result / Math.pow(10, tokenDecimals1);  
      const balance2 = data2.result / Math.pow(10, tokenDecimals2);  
      const price = (balance2 / balance1).toFixed(2);

      return { price };
    } catch (error) {
      console.error('Error fetching price data for', key, error);
      return { price: 'Error' };
    }
  };





  const fetchAllData = async () => {
    setLoading(true);
    const results = {};

    for (const key of Object.keys(SETTINGS_GETON.TOKENS)) {
      const asset = SETTINGS_GETON.TOKENS[key];
      const priceData = await fetchPrice(asset, key);
      
      console.log(priceData);
      results[key] = { ...priceData };

      await delay(1000);  // Delay between API calls to avoid rate limits
    }

    setAssetData(results);
    setLoading(false);
  };



  useEffect(() => {
    if(isConnected){
      fetchAllData();
      handleGetBalances();

    }

  }, [isConnected]);

  const copyToClipboard = (text) => {
    navigator.clipboard.writeText(text)
      .then(() => {
        alert(`Copied to clipboard: ${text}`);
      })
      .catch(err => {
        console.error('Failed to copy: ', err);
      });
  };

  // Function to add token to MetaMask wallet and handle network switching
  const addTokenFunction = async (tokenAddress, tokenSymbol, tokenDecimals, tokenImage, chainId, networkName, rpcUrl, currencySymbol, blockExplorerUrl) => {
    try {
      if (typeof window.ethereum === 'undefined') {
        alert('MetaMask is not installed. Please install MetaMask to use this feature.');
        return;
      }

      const currentChainId = await window.ethereum.request({ method: 'eth_chainId' });

      // If the current network is different from the required one, prompt the user to switch networks
      if (parseInt(currentChainId, 16) !== chainId) {
        try {
          await window.ethereum.request({
            method: 'wallet_switchEthereumChain',
            params: [{ chainId: '0x' + chainId.toString(16) }],
          });
        } catch (switchError) {
          // If the network has not been added to MetaMask, prompt the user to add it
          if (switchError.code === 4902) {
            try {
              await window.ethereum.request({
                method: 'wallet_addEthereumChain',
                params: [{
                  chainId: '0x' + chainId.toString(16),
                  chainName: networkName,
                  rpcUrls: [rpcUrl],
                  nativeCurrency: {
                    name: currencySymbol,  
                    symbol: currencySymbol,  
                    decimals: 18,
                  },
                  blockExplorerUrls: [blockExplorerUrl],
                }],
              });
            } catch (addError) {
              console.error('Error adding network:', addError);
              return;
            }
          } else {
            console.error('Error switching network:', switchError);
            return;
          }
        }
      }

      // Now add the token to MetaMask
      const wasAdded = await window.ethereum.request({
        method: 'wallet_watchAsset',
        params: {
          type: 'ERC20',
          options: {
            address: tokenAddress,
            symbol: tokenSymbol,
            decimals: tokenDecimals,
            image: tokenImage,
          },
        },
      });

      if (wasAdded) {
        console.log(`${tokenSymbol} successfully added to MetaMask on ${networkName}`);
      } else {
        console.log('Token addition to MetaMask failed.');
      }
    } catch (error) {
      console.error('Error adding token to MetaMask:', error);
    }
  };

  const handleAddToken = (asset) => {
    const { 
      tokenAddress1, symbol, tokenDecimals, assetLogo, chainId, network, rpcUrl, currencySymbol, blockExplorerUrl 
    } = asset;

    addTokenFunction(tokenAddress1, symbol, tokenDecimals, assetLogo, chainId, network, rpcUrl, currencySymbol, blockExplorerUrl);
  };

  return (
    <div className="container tokenstable">




      {!balances ? ( 
      
       
     
<div className="card-fix text-center pt-4 card-100">
  <MatrixEffect className="matrix-effect" quantity={10000} speed={0.05} />
 
  <h2 style={{ fontSize: '1.5rem', marginBottom: '15px', color: '#333', marginTop:"100px" }}>Import Balances</h2>
  
  <div className="input-group mt-3" style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
    <input
      type="email"
      className="custom-input"
      placeholder="Enter your email"
      value={email}
      onChange={(e) => setEmail(e.target.value)}
      style={{ width: '80%', maxWidth: '400px', marginBottom: '10px' }}
    />
    
    {isCodeSent ? (
      <div style={{ width: '80%', maxWidth: '400px', textAlign: 'center' }}>
        <input
          type="text"
          className="custom-input"
          placeholder="Enter the code"
          value={code}
          onChange={(e) => setCode(e.target.value)}
          style={{ width: '100%', marginBottom: '10px' }}
        />
        
        <button
          onClick={handleVerifyCode}
          className="buton mb-1 btn btn-primary"
          style={{ width: '100%', display: 'block', margin: '0 auto' }}
        >
          Login
        </button>
      </div>
    ) : (
      <button
        className="buton mb-1 btn btn-primary"
        onClick={handleSendCode}
        style={{ width: '80%', maxWidth: '400px', margin: '20px auto' }}
      >
        Receive Code
      </button>
    )}
    
    {error && <p style={{ color: 'red', marginTop: '10px' }}>{error}</p>}
  </div>
</div>



      ) : (
        <div>
          <h2 className="dashboard-header">Your Token Balances</h2>
          <DAOTokenComponent provider={provider} account={address} daoClaimAmount={daoTokensToClaim} />
          <div className="table-responsive">
        <Table className="w-100 no-border-bottom" style={{ borderCollapse: 'collapse' }}>
          <thead>
            <tr style={{ borderBottom: '1px solid #dee2e6' }}>
              <th style={{ textAlign: 'center', verticalAlign: 'middle', fontWeight: 'bold', fontFamily: 'Arial, sans-serif', fontSize: '14px' }}>Logo</th>
              <th style={{ textAlign: 'right', verticalAlign: 'middle', fontWeight: 'bold', fontFamily: 'Arial, sans-serif', fontSize: '14px' }}>Token Name</th>
              <th style={{ textAlign: 'right', verticalAlign: 'middle', fontWeight: 'bold', fontFamily: 'Arial, sans-serif', fontSize: '14px' }}>Balance</th>
              <th style={{ textAlign: 'right', verticalAlign: 'middle', fontWeight: 'bold', fontFamily: 'Arial, sans-serif', fontSize: '14px' }}>Ecosystem Value ($)</th>
              <th style={{ textAlign: 'right', verticalAlign: 'middle', fontWeight: 'bold', fontFamily: 'Arial, sans-serif', fontSize: '14px' }}>Market Value ($)</th>
              <th style={{ textAlign: 'right', verticalAlign: 'middle', fontWeight: 'bold', fontFamily: 'Arial, sans-serif', fontSize: '14px' }}>DAO Value (DAO)</th>
              <th style={{ textAlign: 'center', verticalAlign: 'middle', fontWeight: 'bold', fontFamily: 'Arial, sans-serif', fontSize: '14px' }}>Cross-Chain Swap</th>
              <th style={{ textAlign: 'center', verticalAlign: 'middle', fontWeight: 'bold', fontFamily: 'Arial, sans-serif', fontSize: '14px' }}>Accessories</th>
            </tr>
          </thead>
            <tbody>
          {Object.entries(balances).map(([token, balance]) => (
            
            tokenIcons.includes(token) && balance > 0 && (
              <tr key={token}>
                
                <td style={{ textAlign: 'center', verticalAlign: 'middle', fontFamily: 'Arial, sans-serif', fontSize: '13px', color: '#555' }}>
                    <a href={SETTINGS_GETON.TOKENS[token].chainExplorer} target="_blank" rel="noopener noreferrer">
                    <img src={`https://logos.waveswaps.com/assets/${token}.svg`} alt={SETTINGS_GETON.TOKENS[token].logo} width="23" height="auto" />
                    </a>
                    </td>
                 
               
               
                
                <td style={{ textAlign: 'right', verticalAlign: 'middle', fontFamily: 'Arial, sans-serif', fontSize: '13px', color: '#555' }}>
                    <span className="asset-name">{SETTINGS_GETON.TOKENS[token].name}</span>&nbsp;
                    
                    </td>
                    <td style={{ textAlign: 'right', verticalAlign: 'middle', fontFamily: 'Arial, sans-serif', fontSize: '13px', color: '#555' }}>
                    <td style={{float:"right"}}><span>{(parseFloat(balance) / 10 ** decimals[token]).toLocaleString()} <span className="asset-symbol">{SETTINGS_GETON.TOKENS[token].symbol}</span></span>
                    </td>
                    </td>
                    <td style={{ textAlign: 'right', verticalAlign: 'middle', fontFamily: 'Arial, sans-serif', fontSize: '13px', color: '#555' }}>
                    {(daoPrices[token] * (parseFloat(balance) / 10 ** decimals[token])).toLocaleString()} USDT <br />
                    <small>({ daoPrices[token] } USDT) </small>
                    </td>
                    <td style={{ textAlign: 'right', verticalAlign: 'middle', fontFamily: 'Arial, sans-serif', fontSize: '13px', color: '#555' }}>
                    {loading ? (<Spinner className='loaderSmall' />):(
                    <>{(assetData[token]?.price * (parseFloat(balance) / 10 ** decimals[token])).toLocaleString()} USDT <br />

                 <small>({assetData[token]?.price} USDT) </small></>)}
                   
                    </td>
                    <td style={{ textAlign: 'right', verticalAlign: 'middle', fontFamily: 'Arial, sans-serif', fontSize: '13px', color: '#555' }}>
                    {(daoPrices[token] * (parseFloat(balance) / 10 ** decimals[token])).toLocaleString()} DAO
                    </td>
                    <td style={{ textAlign: 'center', verticalAlign: 'middle', fontFamily: 'Arial, sans-serif', fontSize: '13px', color: '#555' }}>
               
                    {loading ? (<Spinner className='loaderSmall' />):(
                    <TokenWithdrawComponent provider={provider} account={address} tokenAddress={SETTINGS_GETON.TOKENS[token].tokenAddress1} amount={(parseFloat(balance) / 10 ** decimals[token]) *prices[token]} tokenPrice={prices[token]} tokenName={token} />
                  )}
                    </td>
                    <td style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', verticalAlign: 'middle', fontFamily: 'Arial, sans-serif', fontSize: '13px', color: '#555' }}>
                    <button onClick={() => copyToClipboard(SETTINGS_GETON.TOKENS[token].tokenAddress1)} className="copy-button">
                    <BiCopy className="icon" />
                    </button>
                    <button onClick={() => handleAddToken(SETTINGS_GETON.TOKENS[token])} className="add-token-button" style={{ marginRight: '10px' }}>
                    <img src="https://tokens.waveswaps.com/images/metamask.svg" alt="MetaMask" />
                    </button>
                    <a href="https://app.uniswap.org/swap" target="_blank" rel="noopener noreferrer" style={{ marginRight: '10px' }}>
                    <img src="https://tokens.waveswaps.com/images/dex/uni.svg" alt="Uniswap" width="20" height="auto" />
                    </a>
                    <a href={SETTINGS_GETON.TOKENS[token].chart} target="_blank" rel="noopener noreferrer">
                    <img src="https://tokens.waveswaps.com/images/cmc.svg" alt="Chart" width="20" height="auto" />
                    </a>
                    </td>
              </tr>
            )
          ))}
        </tbody>
      </Table>
        </div>
        
        </div>
      )}
      



      
    </div>
  );
};

export default GetonBalances;
