import React from 'react';
import { Container, Accordion } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';

const FAQs = () => {
  const navigate = useNavigate();

  const openInfo = () => {
    navigate('/info');
  };

  return (
    <Container className="px-0 mt-2">
      <section className="faq faqtable">
        <br />
        <h2 className="pageTitle faq-header">Frequently Asked Questions</h2>
        <p className="hero-p sub-header">
          Find answers to common questions and explore helpful insights in our FAQ section.
        </p>

        <Accordion flush>
          <Accordion.Item eventKey="0" className="mb-2">
            <Accordion.Header>What is GetonGlobal, and what does it aim to achieve?</Accordion.Header>
            <Accordion.Body>
              <p style={{ textAlign: 'justify' }}>
                GetonGlobal is an integral part of a decentralized ecosystem designed to unlock liquidity for tokenized assets, promote community governance, and empower users with innovative tools. It integrates multiple blockchain networks and advanced features such as cross-chain swaps, deflationary tokenomics, and DAO-controlled asset management. Users benefit from transparent, decentralized decision-making while engaging with features like the Price Prediction Game.
              </p>
            </Accordion.Body>
          </Accordion.Item>

          <Accordion.Item eventKey="1" className="mb-2">
            <Accordion.Header>How does the Price Prediction Game work?</Accordion.Header>
            <Accordion.Body>
              <p style={{ textAlign: 'justify' }}>
                The Price Prediction Game allows users to predict whether the WAVE price will increase or decrease within a set time frame. Regardless of whether their prediction is correct, participants earn WAVE rewards based on their subscription tier. These WAVE tokens can be sold or used to purchase Liquidity Nodes on WaveSwaps. Subscriptions can be paid using USDT, WAVE, or points earned through gameplay. This engaging feature helps users explore the ecosystem while earning rewards.
              </p>
            </Accordion.Body>
          </Accordion.Item>

          <Accordion.Item eventKey="2" className="mb-2">
            <Accordion.Header>What happens to balances from the previous Geton Ecosystem?</Accordion.Header>
            <Accordion.Body>
              <p style={{ textAlign: 'justify' }}>
                All 160,000 registered users from the previous Geton Ecosystem will have their balances automatically migrated to the new interface. Users can access these balances by entering their previously registered email and connecting their MetaMask wallet. Balances are distributed across multiple blockchain networks—BNB Smart Chain, Polygon, Optimism, Arbitrum One, and Ethereum—ensuring compatibility with GetonGlobal. 
              </p>
              <p style={{ textAlign: 'justify' }}>
                Users can choose to swap their balances immediately or hold them for greater long-term value. Holding balances contributes to supply reduction, potentially increasing token value. Users also receive DAO tokens proportionally, which are released over 100 days upon claiming. Swapping rates are documented and are subject to DAO voting. Additionally, 49% of WSHARE tokens are allocated proportionally among all previous ecosystem users.
              </p>
            </Accordion.Body>
          </Accordion.Item>

          <Accordion.Item eventKey="3" className="mb-2">
            <Accordion.Header>How can users swap tokens between different blockchains?</Accordion.Header>
            <Accordion.Body>
              <p style={{ textAlign: 'justify' }}>
                GetonGlobal’s cross-chain swap feature enables users to transfer tokens between supported networks instantly. For example, users can swap tokens to a blockchain with a higher market price, adjusting token quantities proportionally to maintain value equivalence. Excess tokens from swaps are sent to the Recycle Pool, reducing circulating supply and enhancing token value through deflationary mechanisms. Swapping rates are transparently documented and adjusted via DAO governance.
              </p>
            </Accordion.Body>
          </Accordion.Item>

          <Accordion.Item eventKey="4" className="mb-2">
            <Accordion.Header>What is the Recycle Pool, and how does it benefit the ecosystem?</Accordion.Header>
            <Accordion.Body>
              <p style={{ textAlign: 'justify' }}>
                The Recycle Pool stores tokens removed from circulation during swaps. By reducing the overall supply, it creates scarcity, which positively impacts token value. The DAO community manages the Recycle Pool and decides whether to hold, burn, or reissue these tokens. This transparent mechanism ensures long-term sustainability and value creation.
              </p>
            </Accordion.Body>
          </Accordion.Item>

          <Accordion.Item eventKey="5" className="mb-2">
            <Accordion.Header>How do token prices adjust across blockchains?</Accordion.Header>
            <Accordion.Body>
              <p style={{ textAlign: 'justify' }}>
                Token prices balance naturally over time through user activities and arbitrage. For instance, if a token’s price on one blockchain is lower than another, users can swap tokens to benefit from better pricing. These activities align token prices across networks, reduce circulating supply, and promote a unified market valuation. Swapping rates are subject to DAO proposals and documented transparently.
              </p>
            </Accordion.Body>
          </Accordion.Item>

          <Accordion.Item eventKey="6" className="mb-2">
            <Accordion.Header>What is DAO governance, and how does it work?</Accordion.Header>
            <Accordion.Body>
              <p style={{ textAlign: 'justify' }}>
                DAO (Decentralized Autonomous Organization) governance ensures community-driven decision-making. Members submit, discuss, and vote on proposals using voting tokens. Decisions include token recycling rates, swap parameters, and platform updates. This system fosters transparency, decentralization, and user-driven innovation.
              </p>
            </Accordion.Body>
          </Accordion.Item>

          <Accordion.Item eventKey="7" className="mb-2">
            <Accordion.Header>How does GetonGlobal’s deflationary model benefit users?</Accordion.Header>
            <Accordion.Body>
              <p style={{ textAlign: 'justify' }}>
                GetonGlobal’s deflationary model reduces token supply by permanently storing excessive tokens in the Recycle Pool. As the supply decreases, demand and token value increase, benefiting long-term holders and creating a sustainable ecosystem.
              </p>
            </Accordion.Body>
          </Accordion.Item>

          <Accordion.Item eventKey="8" className="mb-2">
            <Accordion.Header>Does GetonGlobal offer staking opportunities?</Accordion.Header>
            <Accordion.Body>
              <p style={{ textAlign: 'justify' }}>
                Yes, users can stake GBL and WAVE on WaveSwaps for fixed rewards. Staking provides passive income while enhancing ecosystem liquidity. Rewards are distributed transparently to incentivize long-term participation.
              </p>
            </Accordion.Body>
          </Accordion.Item>

          <Accordion.Item eventKey="9" className="mb-2">
            <Accordion.Header>What role does arbitrage play in GetonGlobal’s ecosystem?</Accordion.Header>
            <Accordion.Body>
              <p style={{ textAlign: 'justify' }}>
                Arbitrage plays a key role in balancing token prices across networks. By leveraging price differences between blockchains, users stabilize markets while reducing excessive supply, ultimately benefiting liquidity and token value.
              </p>
            </Accordion.Body>
          </Accordion.Item>

          <Accordion.Item eventKey="10" className="mb-2">
            <Accordion.Header>What happens to fees collected from user activities?</Accordion.Header>
            <Accordion.Body>
              <p style={{ textAlign: 'justify' }}>
                Fees collected from swaps, staking, and games are deposited into profit center smart contracts. These funds are distributed to WSHARE token holders via DAO governance, ensuring that the community directly benefits from platform revenues.
              </p>
            </Accordion.Body>
          </Accordion.Item>

          <Accordion.Item eventKey="11" className="mb-2">
            <Accordion.Header>How does GetonGlobal ensure security and transparency?</Accordion.Header>
            <Accordion.Body>
              <p style={{ textAlign: 'justify' }}>
                GetonGlobal prioritizes security and transparency by securely transferring user records to the Slovenian court for compliance review. The platform operates through decentralized protocols, and all decisions are managed via DAO governance. Users can safely test features using faucet tokens before engaging with real assets.
              </p>
            </Accordion.Body>
          </Accordion.Item>
        </Accordion>
      </section>
    </Container>
  );
};

export default FAQs;
